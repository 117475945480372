import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core";
import { InvoiceStatus } from "../types/InvoiceTypes";

export interface InvoiceStatusDisplay {
  label?: string;
  textColor?: string;
  backgroundColor?: string;
}

export const useInvoiceStatus = (): {
  getStatusList: () => InvoiceStatus[];
  getStatusDisplay: (status: InvoiceStatus) => InvoiceStatusDisplay;
} => {
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultStatusDisplay: InvoiceStatusDisplay = {
    label: "",
  };

  const allStatus: Record<InvoiceStatus, InvoiceStatusDisplay> = {} as Record<
    InvoiceStatus,
    InvoiceStatusDisplay
  >;

  allStatus[InvoiceStatus.Draft] = {
    ...defaultStatusDisplay,
    label: t("InvoiceStatusDraft"),
    textColor: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main,
  };

  allStatus[InvoiceStatus.Closed] = {
    ...defaultStatusDisplay,
    label: t("InvoiceStatusClosed"),
    textColor: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main,
  };

  allStatus[InvoiceStatus.PayedPartial] = {
    ...defaultStatusDisplay,
    label: t("InvoiceStatusPayedPartial"),
    textColor: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  };

  allStatus[InvoiceStatus.Unpayed] = {
    ...defaultStatusDisplay,
    label: t("InvoiceStatusUnpayed"),
    textColor: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
  };

  allStatus[InvoiceStatus.PayementStarted] = {
    ...defaultStatusDisplay,
    label: t("InvoiceStatusPayementStarted"),
    textColor: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main,
  };

  allStatus[InvoiceStatus.Credit] = {
    ...defaultStatusDisplay,
    label: t("InvoiceStatusCredit"),
    textColor: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  };

  allStatus[InvoiceStatus.PayedFinalready] = {
    ...defaultStatusDisplay,
    label: t("InvoiceStatusPayedFinalready"),
    textColor: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  };

  allStatus[InvoiceStatus.Payed] = {
    ...defaultStatusDisplay,
    label: t("InvoiceStatusPayed"),
    textColor: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  };

  const getStatusList = (): InvoiceStatus[] =>
    Object.keys(allStatus) as unknown as InvoiceStatus[];

  const getStatusDisplay = (status: InvoiceStatus): InvoiceStatusDisplay =>
    allStatus[parseInt(status.toString()) as InvoiceStatus]; // status coming from API can be strings instead of numbers sometimes

  return { getStatusList, getStatusDisplay };
};
