import React, { useEffect, useState } from "react";
import Content from "../components/core/layout/Content";
import Header from "../components/core/Header";
import HeaderActions from "../components/core/HeaderActions";
import { useTranslation } from "react-i18next";
import { Description, FilterList, MoreHoriz } from "@material-ui/icons";
import { useDevice } from "../hooks/useDevice";
import CircleButton from "../components/core/CircleButton";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  makeStyles,
  Popover,
  Typography,
  useTheme,
} from "@material-ui/core";
import Datagrid from "../components/core/Datagrid";
import NoInvoice from "../features/invoices/components/NoInvoice";
import InvoicesFilters from "../features/invoices/components/filters/InvoicesFilters";
import {
  Invoice,
  InvoiceFilter,
  InvoiceSort,
  InvoiceSortFields,
} from "../features/invoices/types/InvoiceTypes";
import { useInvoices } from "../features/invoices/hooks/useInvoices";
import NoFilterResult from "../components/core/NoFilterResult";
import moment from "moment";
import { useInvoiceStatus } from "../features/invoices/hooks/useInvoiceStatus";

const Invoices: React.FC = () => {
  const classes = makeStyles((theme) => ({
    root: {
      backgroundColor: "white",
      padding: theme.spacing(4),
      boxShadow: "0px 5px 15px rgba(115, 55, 0, 0.15)",
      borderRadius: theme.spacing(0.5),
      justifyContent: "center",
    },
  }))();
  const { t } = useTranslation();
  const device = useDevice();
  const [page, setPage] = useState(0);
  const rowsPerPage = 50;
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<InvoiceFilter>({});
  const someFilters = Object.values(filters).some((value) => !!value);
  const [sort, setSort] = useState<InvoiceSort>({ datef: "DESC" });
  const [isLoading, setLoading] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [displayFilter, setDisplayFilter] = useState(false);
  const { searchInvoices } = useInvoices();
  const { getStatusDisplay } = useInvoiceStatus();

  useEffect(() => {
    setLoading(true);
    searchInvoices(rowsPerPage, page, filters, sort)
      .then((res) => {
        setInvoices(res.results);
        setTotal(parseInt(res.total));
      })
      .finally(() => setLoading(false));
  }, [filters, page, rowsPerPage, searchInvoices, sort]);

  return (
    <>
      <Header
        focus={false}
        title={t("MenuInvoices")}
        titleIcon={<Description />}
      >
        <HeaderActions>
          {device.isMobile ? (
            <>
              <CircleButton
                color="primary"
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                <FilterList />
              </CircleButton>
            </>
          ) : (
            <>
              <Button
                color="primary"
                endIcon={<FilterList />}
                onClick={() => {
                  setDisplayFilter(!displayFilter);
                }}
              >
                {t("ToggleFiltersLabel")}
              </Button>
            </>
          )}
        </HeaderActions>
      </Header>
      <Content>
        {(total > 0 || someFilters) && (
          <Collapse in={displayFilter}>
            <InvoicesFilters applyFilters={(filters) => setFilters(filters)} />
          </Collapse>
        )}
        {!isLoading && total === 0 ? (
          <>
            {someFilters ? (
              <NoFilterResult />
            ) : (
              <Box className={classes.root}>
                <NoInvoice />
              </Box>
            )}
          </>
        ) : (
          <Datagrid
            rows={invoices}
            rowsPerPage={rowsPerPage}
            page={page}
            total={total}
            enablePageSelector
            onChangePage={(p) => setPage(p)}
            columnsDef={[
              {
                header: t("InvoicesHeaderRef"),
                format: (value) => value.ref,
                colWidth: "120px",
                sortOnField: InvoiceSortFields.ref,
              },
              {
                header: t("InvoicesHeaderState"),
                format: (value) => (
                  <Typography
                    style={{
                      color: getStatusDisplay(value.status).textColor,
                      backgroundColor: getStatusDisplay(value.status)
                        .backgroundColor,
                      padding: "4px 12px",
                      fontSize: 12,
                      fontWeight: 500,
                      lineHeight: "12px",
                      textTransform: "uppercase",
                      textAlign: "center",
                      borderRadius: 4,
                    }}
                  >
                    {getStatusDisplay(value.status).label}
                  </Typography>
                ),
                colWidth: "200px",
              },
              {
                header: t("InvoicesHeaderRefInter"),
                format: (value) => value.intervention.ref,
              },
              {
                header: t("InvoicesHeaderDateCreation"),
                format: (value) =>
                  value.intervention.dateCreat
                    ? moment.unix(value.intervention.dateCreat).format("L")
                    : "-",
                colWidth: "auto",
              },
              {
                header: t("InvoicesHeaderDistriName"),
                format: (value) => value.distributor.name,
                colWidth: "200px",
              },
              {
                header: t("InvoicesHeaderDistriCode"),
                format: (value) => value.distributor.distributerCode,
              },
              {
                header: t("InvoicesHeaderGarageName"),
                format: (value) => value.garage.name,
                colWidth: "200px",
              },
              {
                header: t("InvoicesHeaderClientCode"),
                format: (value) => value.garage.customerCode,
              },
              {
                header: t("InvoicesHeaderShopCode"),
                format: (value) => value.garage.shopCode,
              },
              {
                header: t("InvoicesHeaderDateIssue"),
                format: (value) =>
                  value.issueDate
                    ? moment.unix(value.issueDate).format("L")
                    : "-",
                colWidth: "auto",
                sortOnField: InvoiceSortFields.datef,
              },
              {
                header: t("InvoicesHeaderDateDue"),
                format: (value) =>
                  value.dueDate ? moment.unix(value.dueDate).format("L") : "-",
                colWidth: "auto",
                sortOnField: InvoiceSortFields.date_lim_reglement,
              },
              {
                header: t("InvoicesHeaderAmount"),
                format: (value) => value.amount,
                sortOnField: InvoiceSortFields.total_ttc,
              },
              {
                header: "",
                format: (value) => <ColActions value={value} />,
                colWidth: "auto",
              },
            ]}
            sort={sort}
            lastColSticky
            onSort={(sort) => setSort(sort)}
            loading={isLoading}
          />
        )}
      </Content>
    </>
  );
};

const ColActions: React.FC<{
  value: Invoice;
}> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { getInvoice } = useInvoices();
  const classes = makeStyles({
    root: {
      padding: theme.spacing(0.5),
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontWeight: 700,
      paddingLeft: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
    },
    action: {
      justifyContent: "flex-start",
      color: theme.palette.primary.main,
      textTransform: "initial",
      "& p": {
        fontWeight: 500,
      },
    },
  })();
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLButtonElement | undefined
  >();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget as HTMLButtonElement);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.root}>
          <Typography variant="caption" className={classes.title}>
            {t("InvoicesActionsMenuTitle")}
          </Typography>
          <Button
            className={classes.action}
            size="small"
            onClick={() => {
              getInvoice(props.value.id).then((result) => {
                const link = document.createElement("a");
                link.href =
                  "data:application/octet-stream;base64," + result.content;
                link.download = result.filename;
                document.body.append(link);
                link.click();
                link.remove();
              });
            }}
          >
            <Typography variant="body2">
              {t("InvoiceActionDownload")}
            </Typography>
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default Invoices;
